import React from "react";
import styled from "styled-components";
import Title from "./../Title/Title";
import { TestimonialList, TestimonialListContainer } from "./TestimonialList";

const Testimonial = () => {
  return (
    <Container>
      <Title>Client testimonials</Title>
      <TestimonialListContainer>
        <TestimonialList
          content="“42ne has completely surpassed our expectations. It really saves me time and effort. 42ne is exactly what our business has been lacking.”"
          name="Dale, United States"
          avatar="/assets/testimonial-profile-1.jpg"
          active
        />
        <TestimonialList
          content="“Definitely worth the investment. Wow what great service, I love it! 42ne is awesome! I was amazed at the quality.”"
          avatar="/assets/testimonial-profile-2.jpg"
          name="Clara, Spanish"
        />
      </TestimonialListContainer>
    </Container>
  );
};

export default Testimonial;

const Container = styled.div`
  margin-bottom: 100px;
`;
